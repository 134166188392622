import { Component } from 'react';
import { BrowserLanguage } from '../../entities/BrowserLanguage';
import CloseIcon from '@material-ui/icons/Close';
import more_info_defulte_icon from '../../assets/images/more_info_defulte_icon.png';
import './MoreInfo.scss';
import { MoreInfoData, StationConnector } from '../../entities/MoreInfoData';
import afkon from '../../assets/Providers/ev-on.png';
import charge from '../../assets/Providers/charge.png';
import greenspot from '../../assets/Providers/greenspot.png';
import sonol from '../../assets/Providers/sonol.png';
import gnrgy from '../../assets/operator_logo/Gnrgy.png';
import evedge from '../../assets/operator_logo/EvEgde_Logo.png';
import evcharge from '../../assets/operator_logo/EVCharge_Logo.png';
import { ConnectorType, IncludesType, ProviderConsts } from '../../entities/iconData';
import { ReactComponent as Wifi_icon } from '../../assets/Incloud/wifi_icon.svg';
import { ReactComponent as Loading_icon } from '../../assets/Incloud/loading_icon.svg';
import { ReactComponent as Parking_icon } from '../../assets/Incloud/parking_icon.svg';
import { ReactComponent as Restoom_icon } from '../../assets/Incloud/restoom_icon.svg';
import { ReactComponent as Shopping_icon } from '../../assets/Incloud/shopping_icon.svg';
import { ReactComponent as Type_2_img } from '../../assets/Sockets/type_2_icon.svg';
import { ReactComponent as Combo_icon } from '../../assets/Sockets/combo_icon.svg';
import { ReactComponent as Phase_icon } from '../../assets/Sockets/phase_icon.svg';
import { ReactComponent as Schuko_icon } from '../../assets/Sockets/schuko_icon.svg';
import { StringsData } from '../../entities/StringsData';

class MoreInfo extends Component<MoreInfoProps, MoreInfoState> {

    render() {
        const { moreInfoData, openMoreInfoPopup, browserLanguage, moreInfoPopup, stringsFromServer } = this.props;
        return (
            <div className={openMoreInfoPopup ? `more-info ${browserLanguage}` : `not-show-more-info ${browserLanguage}`}>
                {moreInfoData && openMoreInfoPopup &&
                    <div>
                        <div className="close-icon-more-info"
                            onClick={() => moreInfoPopup()}
                        >
                            <CloseIcon />
                        </div>
                        <div className="station-img">
                            <img src={more_info_defulte_icon} alt="" />
                        </div>
                        <div className="station-info-first">
                             <img className="img-provider" src={this.getProviderImage(moreInfoData.providerID)} alt="" />
                       
                       {/*      <div className="address-title">
                                {stringsFromServer.site_StationInfoAddress}
                            </div> */}
                            <div className="station-name">
                                {moreInfoData.name}
                            </div>
                            <div className="street-name text-cellopark">
                                {moreInfoData.streetName}
                            </div>
                        </div>

                        <div className="connections-station-info">
                            <div className="connections-text text-cellopark">
                                {stringsFromServer.site_FilterByConnType}
                            </div>
                            <div className="connector-num">
                                {this.updatecConnectorTypes().map(connector => {
                                    return (
                                        <div className="power-num-img">
                                            <div>{this.getConnectorName(connector.connectorType.toString())}</div>
                                            <div className="connector-img">
                                                <span className="">
                                                    {this.getConnectorImage(connector.connectorType.toString())}
                                                     <label className="circle">
                                                        {connector.counter } {console.log("this connector :", moreInfoData.connectorTypes)}
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="price-info">
                            <div className="price-text text-cellopark">
                            {stringsFromServer.site_StationInfoPrices}
                            </div>
                            <div className="connector-price">
                                {this.updatecConnectorTypes().map(connector => {
                                    return (
                                        <div className="power-price-img">
                                            <div className={`power-price-info ${browserLanguage}`}>
                                                <div className="power-info">{connector.power} Kw</div>
                                                <div className="price-connector">{connector.price}&nbsp;{this.getPowerUnitStr()}</div>
                                            </div>
                                            <div className="connector-img">
                                                {this.getConnectorImage(connector.connectorType.toString()) }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>


    {/*                     <div className="include-info">
                            <div className="include-title text-cellopark">
                                {stringsFromServer.site_FilterByInclude}
                            </div>
                            <div className="include-images">

                                {moreInfoData?.serviceTypesArr.map(include => {
                                    return (
                                        <span className="label-img-include-info">
                                            <span className="img-include-info">{this.getIncludesImage(include)} </span>
                                            <label className="label-include-info">{this.getIncludesText(include)}</label>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>




 */}
                    </div>
                }
            </div>
        )
    }

    updatecConnectorTypes(): StationConnector[] {
        const connectorTypeClass = new ConnectorType();
        const { moreInfoData } = this.props;
        let combo = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.COMBO);
        let phase = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.PHASE);
        let type_2 = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.TYPE_2);
        let schuko = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.SCHUKO);
        let CHAdeMO = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.CHADEMO);
        let other = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.OTHER);
        
       
        let connectorTypes: StationConnector[] = [];
        if (combo && combo.length > 0) {
            let cntr = 0;
            combo.map(cmb=>cntr += cmb.connectorCounter);
            combo[0].counter = cntr;
            connectorTypes.push(combo[0])
        }
        if (phase && phase.length > 0) {
            let cntr = 0;
            phase.map(cmb=>cntr += cmb.connectorCounter);
            phase[0].counter = cntr;
           // phase[0].counter = phase.length;
            connectorTypes.push(phase[0])
        }
        if (type_2 && type_2.length > 0) {
            let cntr = 0;
            type_2.map(cmb=>cntr += cmb.connectorCounter);
            type_2[0].counter = cntr;
           // type_2[0].counter = type_2.length;
            connectorTypes.push(type_2[0])
        }
        if (schuko && schuko.length > 0) {
            let cntr = 0;
            schuko.map(cmb=>cntr += cmb.connectorCounter);
            schuko[0].counter = cntr;
           // schuko[0].counter = schuko.length;
            connectorTypes.push(schuko[0])
        }
        if (CHAdeMO && CHAdeMO.length > 0) {
            let cntr = 0;
            CHAdeMO.map(cmb=>cntr += cmb.connectorCounter);
            CHAdeMO[0].counter = cntr;
           // schuko[0].counter = schuko.length;
            connectorTypes.push(CHAdeMO[0])
        }
        if (other && other.length > 0) {
            let cntr = 0;
            other.map(cmb=>cntr += cmb.connectorCounter);
            other[0].counter = cntr;
           // schuko[0].counter = schuko.length;
            connectorTypes.push(other[0])
        }
        return connectorTypes;
    }

    getProviderImage = (providerId: number) => {
        const providerConsts = new ProviderConsts();

        switch (providerId) {
            case providerConsts.PROVIDER_AFKON: {
                return (afkon)
            }
            case providerConsts.PROVIDER_GNRGY: {
                return (gnrgy)
            }
            case providerConsts.PROVIDER_CHARGE: {
                return (evcharge)
            }
            case providerConsts.PROVIDER_GREENSPOT: {
                return (greenspot)
            }
            case providerConsts.PROVIDER_SONOL: {
                return (sonol)
            }
            case providerConsts.PROVIDER_EVEGDE: {
                return (evedge)
            }
            default: {
                return (charge)
            }
        }

    }

    getIncludesImage = (includeNumber: string): JSX.Element => {
        switch (includeNumber) {
            case (IncludesType.WIFI):
                return (<Wifi_icon />);
            case (IncludesType.RESTOOM):
                return (<Restoom_icon />);
            case (IncludesType.LOADING):
                return (<Loading_icon />);
            case (IncludesType.SHOPPING):
                return (<Shopping_icon />);
            case (IncludesType.PARKING):
                return (<Parking_icon />);
            default:
                return (<span />)
        }
    }

    getIncludesText = (includeNumber: string): string => {
        const { stringsFromServer } = this.props;

        switch (includeNumber) {
            case (IncludesType.WIFI):
                return (stringsFromServer.site_FilterWifi);
            case (IncludesType.RESTOOM):
                return (stringsFromServer.site_FilterRestroom);
            case (IncludesType.LOADING):
                return (stringsFromServer.site_FilterLoading);
            case (IncludesType.SHOPPING):
                return (stringsFromServer.site_FilterShopping);
            case (IncludesType.PARKING):
                return (stringsFromServer.site_FilterParking);
            default:
                return ('');
        }
    }

    getConnectorName = (connectorNumber: string): string => {
        const connectorType = new ConnectorType();
        const COMBO = connectorType.COMBO;
        const PHASE = connectorType.PHASE;
        const TYPE_2 = connectorType.TYPE_2;
        const SCHUKO = connectorType.SCHUKO;

        switch (connectorNumber) {
            case (COMBO):
                return ("combo");
            case (PHASE):
                return ("pahse");
            case (TYPE_2):
                return ("type2");
            case (SCHUKO):
                return ("schuko");
            default:
                return ('combo');
        }
    }

    getPowerUnitStr(): string  {
        switch (this.props.browserLanguage) {
            case BrowserLanguage.en: {
                return ('NIS');
            }
            case BrowserLanguage.he: {
                return ('ש"ח');
            }
            case BrowserLanguage.ru: {
                return ('NIS');
            }
            case BrowserLanguage.ar: {
                return ('NIS');
            }
            default: {
                return ('NIS');
            }
        }
    }
    getConnectorImage = (connectorNumber: string): JSX.Element => {
        const connectorType = new ConnectorType();
        const COMBO = connectorType.COMBO;
        const PHASE = connectorType.PHASE;
        const TYPE_2 = connectorType.TYPE_2;
        const SCHUKO = connectorType.SCHUKO;

        switch (connectorNumber) {
            case (COMBO):
                return (<Combo_icon />);
            case (PHASE):
                return (<Phase_icon />);
            case (TYPE_2):
                return (<Type_2_img />);
            case (SCHUKO):
                return (<Schuko_icon />);
            default:
                return (<Combo_icon />)
        }
    }

}
export default MoreInfo;

interface MoreInfoProps {
    moreInfoPopup: Function;
    browserLanguage: BrowserLanguage;
    openMoreInfoPopup: boolean;
    moreInfoData?: MoreInfoData;
    stringsFromServer: StringsData;

}

interface MoreInfoState {
}