
export interface AddressInfo { //extends Coordinates
    // connectorsCounter: number;
    id: number;
    lng: number; //latitude (unless this is how you get it back from the API call)
    lat: number; //longitude 
    maxPower: number;
    name: string;
    provID: number;
    providerName: string;
    providerType: number
    serviceTypes: string[];
    refId: string;
    status: number;
    streetName: string;
    moreInfoStationData?: MoreInfoStationData;
    distanceFromUser: number;
    distanceFromCenterOfMap: number;
    priceDesc: string;
}
export interface MoreInfoStationData {
    connectorCounter: number;
    fastConnectorCounter:number;
    connectorTypesArr: string[];
    maxPower: number;
    minPower: number;
    paymentTypesArr: string[];
    serviceTypesArr: string[];
    stationID: number;
    hide: boolean;
}

export interface ConnectorTypes {
    connectorType: number;
    power: number;
    connectorCounter: number;
 

}

export interface BoundsCord {
    ne: Coordinates;
    nw: Coordinates;
    se: Coordinates;
    sw: Coordinates;
}

export interface Coordinates {
    lng: number;
    lat: number;
}

export interface SearchOption {
    name: string;
    address:string;
    id: number;
    status: number;
    distanceFromUser: number;
    connectorTypesArr: string[];
}

export interface LanguageOption {
    value: string;
    label: any;
    // labelText: string;
}

export class ProviderConsts {
    
    readonly PROVIDER_EVEGDE = 1;
    readonly PROVIDER_CHARGE = 2;
    readonly PROVIDER_GNRGY = 3;
    readonly PROVIDER_SONOL = 30;
    readonly PROVIDER_NETZER = 33;
    readonly PROVIDER_EV4U = 34;
    readonly PROVIDER_EDGE_CONTROL = 35;
    readonly PROVIDER_SCALAEV = 36;
    readonly PROVIDER_VIMORE = 37;
    readonly PROVIDER_NOFAR = 38;
    readonly PROVIDER_AFKON = 29;
    readonly PROVIDER_GREEMS = 39;
    readonly PROVIDER_TDSD = 40;
    readonly PROVIDER_SEVENEV = 41;
    readonly PROVIDER_GREENSPOT = 6;
    readonly PROVIDER_DORALURBAN = 43;
    readonly PROVIDER_LISHATECH = 44;    
    readonly PROVIDER_INTEREV = 45;    
    readonly PROVIDER_ENOVA = 46;
    readonly PROVIDER_ZENEV = 47;

    readonly PROVIDER_EV_TECH = 48;
    readonly PROVIDER_ADVICE = 49;
    readonly PROVIDER_AMISRA_GREEN = 50;
    readonly PROVIDER_EV_PLUS = 51;
    
}

//ALL_CONSTANTS_SHOULD_BE_IN_UPPERCASE
export class ConstClass {  //weird name; find a better one or just ungroup and have them be standalone "Constants"
    readonly allTypesConnectors = '100';
    readonly allTypesOperators = 200;  //seems weird to use mixed types; either all strings or all numbers
    readonly allTypesPayment = '300';
    readonly allTypesIncludes = '400';
}

export class ConnectorType {
    readonly COMBO = '2';
    readonly PHASE = '4';
    readonly TYPE_2 = '1';
    readonly SCHUKO = '3';
    readonly CHADEMO = '5';
    readonly OTHER = '0';

}

export class PriceType {
    readonly by_card = '1';
    readonly by_cash = '2';
    readonly by_phone = '3';
    readonly free = '4';
}

export enum IncludesType {
    WIFI = '1',
    RESTOOM = '2',
    LOADING = '3',
    SHOPPING = '4',
    PARKING = '5',
}

export interface Cluster {
    coordinates: Coordinates;
    counter: number
}